
.loginPopup{
    margin: 45% auto !important; 
    width: 90% !important; 
    border-radius: 80px 80px 0px 80px !important;
  }
  
  .loginPopupTop{
    margin: 40% auto !important;  
    width: 80% !important;
    border-radius: 80px 80px 0px 80px !important;
  }