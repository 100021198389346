.AmbassadorMain{
    background-color: #160133;
}
.AmbassadorMainNew{
    background-color: #160133;
}

@media(max-width: 750px) {
    .stepContainer{
        display: table;
        width: 100%;
        margin: 0px auto; 
    }
}
@media(min-width: 750px) {
    .stepContainer{
        display: table;
        width: 40%;
        margin: 0px auto; 
    }
}
.tableCell{
    width: 0.5em;
    display: table-cell;
    position: relative;
    padding-top: 0px;
}
.step{
    width: 4rem;
    height: 4rem;
    margin: 0px auto;
    flex-shrink: 0;
    border-radius: 100%;
    text-align: center;
    padding: 1px;
    font-size: 10px;
    color: rgb(255, 255, 255);
    display: block;
    border-color:#593EDA;
    fill: linear-gradient(180deg, #604EE5 0%, #4102B4 100%);
    background-image: url("assets/images/Ellipse.svg");
}
.step img{
    width:3em;
}
.stepConnecterNext{
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 1px solid rgb(82, 14, 175);
    right: 0px;
    left: 42%;
    margin-left: 54px;
}
.stepConnecterPrev{
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 1px solid rgb(82, 14, 175);
    left: 0px;
    right: 42%;
    margin-right: 54px;
}

.buttonText{
    display: block;
    text-decoration: none;
    background: linear-gradient(180deg, #FEFEFE 35.94%, #3C1ABA 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    text-align: center;
    font-family: Poppins;
    font-size: 0.80rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0275rem;

    /* padding-top: 1.5rem; */
}
.textsomechange span{
    font-size: 1.2em;
    color:#ffd02c;
    font-weight: 700;
}

.headerTitle{
    text-align:center;
    color: #FEFEFE;
    text-shadow: 0px 1px 3px #5B03FF;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0375rem;
    text-transform: uppercase;
    margin-top:0.5em;
}



.subHeaderText{
    background: linear-gradient(180deg, #FEFEFE 35.94%, #3C1ABA 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 800;
    line-height: 5;
    letter-spacing: 0.0275rem;        
}
.description{
    color: #FFF;
    font-family: Poppins;
    font-style: normal;

    
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
}
.footerText{
    
    text-align:center;
    color: #FEFEFE;
    text-shadow: 0px 1px 3px #5B03FF;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0375rem;
    margin-top:2.6em;
}

.footerAmb{
    color: white;
letter-spacing:2px;

/* Will override color (regardless of order) */
  /* -webkit-text-fill-color: white; 
  background: -webkit-linear-gradient(180deg, rgba(243,236,60,1) 0%, rgba(212,176,92,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  /* text-transform: uppercase;   */
  /* text-shadow: 0px 1px 3px 0px #5B03FF; */
  /* font-family: 'Lilita One'; */ 
  text-align: center;
  font-size: 1.2rem;
}
.foreverText{
    color: #FEFEFE;
    font-family: Poppins;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 725;
    line-height: 2.1875rem;
    letter-spacing: 0.02375rem;
    
}
.inviteButton{
    position:relative;
    margin-top:1.5em;
    padding-top:0.5em;
    padding-bottom:1.5em;
    padding-left:1em;
    padding-right:1em;
    flex-shrink: 0;
    border-radius: 0.625em !important;
    border-color:#543CD6;
    background: #543CD6 !important;
    bottom:0.2em;
    color: #FEFEFE;
    text-shadow: 0px 1px 3px #5B03FF;
    font-family: Poppins;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.035em;
    
    text-transform: uppercase;
}
.inviteButton span{
    position: relative;
    top: 0.3em;
}
.shareBox{
    padding-top:1em;
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    width: 11.9rem;
    height: 3.76856rem;
    flex-shrink: 0;
    background-image: url("assets/images/tooltip.svg");
}
.shareBox img,span{
    cursor:pointer;
}
.shareSubHeaderText{
   
    background-clip: text;
    font-family: Poppins;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.0275rem;
}
.footerAmb2{
    color: #e6d348;
letter-spacing:2px;

  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  background: -webkit-linear-gradient(180deg, rgba(243,236,60,1) 0%, rgba(212,176,92,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  /* text-transform: uppercase;   */
  text-shadow: 0px 1px 3px 0px #5B03FF;
  font-family: 'Lilita One';
  text-align: center;
  font-size: 1.2rem;
}